<template>
  <div class="row mx-2 my-0">
    <div
      class="
        col-12 col-md-10 col-lg-8
        offset-md-1 offset-lg-2
        card
        theme-card
        border-0
        py-5
        px-4
      "
    >
      <h1 class="mb-5">{{ categoryId ? "Edit" : "Create" }} Intake Category</h1>
      <alert v-if="loading" class="light-shadow mx-4 mb-4" />
      <form
        v-else
        @submit.prevent="createEditCategory"
        validate
        class="d-flex flex-column"
      >
        <input-control
          type="horizontal"
          v-model.trim="category.type_id"
          labelClass="col-md-3 col-lg-2"
          required
          maxLength="255"
        >
          ID
        </input-control>
        <input-control
          type="horizontal"
          v-model.trim="category.name"
          labelClass="col-md-3 col-lg-2"
          required
          maxLength="255"
        >
          Name
        </input-control>
        <select-control
          type="horizontal"
          :options="statusOptions"
          required
          labelClass="col-12 col-md-3 col-lg-2"
          v-model="category.status"
          >Status</select-control
        >
        <save
          classes="mt-3 save-button btn btn-theme align-self-end"
          :saving="saving"
        >
          {{ category.id ? "Update" : "Save" }}
        </save>
      </form>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      category: {
        id: null,
        type_id: null,
        name: "",
        status: "ACTIVE",
      },
      statusOptions: [
        {
          value: "ACTIVE",
          label: "Active",
        },
        { value: "INACTIVE", label: "Inactive" },
      ],
      loading: false,
      saving: false,
    };
  },
  async mounted() {
    if (this.categoryId) {
      this.loading = true;
      this.category = this.categories.filter(
        (cat) => cat.id == this.categoryId
      )[0];
      if (!this.category) {
        this.$router.push(`/admin/intake-categories`);
        return;
      }
      this.loading = false;
    }
  },
  computed: {
    ...mapState({
      categories: (state) => state.intakeForms.categories.data,
    }),
    categoryId() {
      return this.$route.params.id;
    },
  },
  methods: {
    ...mapActions({
      createIntakeCategory: "intakeForms/createCategory",
      updateIntakeCategory: "intakeForms/updateCategory",
      getIntakeCategories: "intakeForms/getCategories",
    }),
    createEditCategory: async function () {
      if (isNaN(this.category.type_id)) {
        return Swal.fire({
          title: "Validation Error",
          text: "ID should be a number",
          icon: "error",
        });
      }
      let result;
      this.saving = true;
      if (this.categoryId) {
        result = await this.updateIntakeCategory(this.category);
      } else {
        result = await this.createIntakeCategory(this.category);
      }

      this.saving = false;

      if (result) {
        Swal.fire({
          title: "Success",
          text: `Intake category ${
            this.categoryId ? "updated" : "created"
          } successfully`,
          icon: "success",
        });
        this.getIntakeCategories();
        this.$router.push(`/admin/intake-categories`);
      }
    },
  },
};
</script>
